import { FC, forwardRef, ReactElement, Ref, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { TransitionProps } from '@mui/material/transitions'
import { isFinite } from 'lodash'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slide,
  TextField,
} from '@mui/material'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { actionBalance, EActionBalance } from '../../constants/actionBalance'
import { formPropsDrawerCrud } from '../../constants/formPropsDrawerCrud'
import { ApiPlayers } from '../../api'
import { moneyFormat } from '../../utils/money'

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement<any, any>
    },
    ref: Ref<unknown>
  ) => {
    return <Slide direction="left" ref={ref} {...props} />
  }
)

type DialogAddPlayerBalanceProps = {
  isOpen: boolean
  playerId: string
  playerLogin: string
  onClose: () => void
  onSave?: (playerId: string, balance: number, type: EActionBalance, comment: string) => void
}

const DialogAddPlayerBalance: FC<DialogAddPlayerBalanceProps> = observer(
  ({ isOpen, playerId, playerLogin, onSave, onClose }) => {
    const [balance, setBalance] = useState('')
    const [formattedBalance, setFormattedBalance] = useState('')
    const [convertBalance, setConvertBalance] = useState(0)
    const [type, setType] = useState<EActionBalance.REFILL | EActionBalance.WITHDRAWAL>(EActionBalance.REFILL)
    const [comment, setComment] = useState('')

    const handleClose = (): void => {
      onClose()
    }

    const formatNumber = (value: string): string => {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }

    useEffect(() => {
      const numericValue = balance.replace(/\s/g, '')
      setConvertBalance(parseInt(numericValue, 10) || 0)
      setFormattedBalance(formatNumber(numericValue))
    }, [balance])

    const { data: playerBalance, isError } = useQuery(
      `playerId: ${playerId} balance`,
      async () => {
        return ApiPlayers.getPlayerBalanceById(playerId)
      },
      {
        enabled: !!playerId,
        onError: (error) => {
          toast.error(`${error}`)
        },
      }
    )

    if (isError) return null

    const maxWithdrawal = Number(playerBalance?.balance) || 0

    return (
      <Dialog fullWidth maxWidth="sm" open={isOpen} TransitionComponent={Transition} onClose={handleClose}>
        <DialogTitle>Тип операции для: {playerLogin}</DialogTitle>
        <DialogContent>
          <Box>
            <FormControl>
              <RadioGroup
                value={type}
                onChange={(e) => {
                  setType(e.target.value as EActionBalance.REFILL | EActionBalance.WITHDRAWAL)
                }}>
                <FormControlLabel
                  value={EActionBalance.REFILL}
                  control={<Radio />}
                  label={actionBalance[EActionBalance.REFILL]}
                />
                <FormControlLabel
                  value={EActionBalance.WITHDRAWAL}
                  control={<Radio />}
                  label={actionBalance[EActionBalance.WITHDRAWAL]}
                />
              </RadioGroup>
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                gap: 2,
              }}>
              {type === EActionBalance.WITHDRAWAL && (
                <Alert severity="warning">Доступно для списания: {moneyFormat(String(maxWithdrawal))}</Alert>
              )}

              <TextField
                {...formPropsDrawerCrud}
                label="Cумма"
                autoFocus
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9\s]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                InputProps={{ inputProps: { type: 'text', min: 0 } }}
                error={!balance || (type === EActionBalance.WITHDRAWAL && convertBalance > maxWithdrawal)}
                value={formattedBalance}
                onChange={(e) => {
                  const newValue = e.target.value

                  const numericValue = newValue.replace(/\s/g, '')

                  if (numericValue === '' || (Number(numericValue) > 0 && !Number.isNaN(Number(numericValue)))) {
                    setBalance(numericValue)
                  }
                }}
              />
              <TextField
                fullWidth
                label="Комментарий"
                multiline
                rows={4}
                value={comment}
                onChange={(e) => {
                  e.preventDefault()

                  setComment(e.target.value)
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отменить</Button>
          <Button
            disabled={!balance || (type === EActionBalance.WITHDRAWAL && convertBalance > maxWithdrawal)}
            onClick={(e) => {
              e.preventDefault()

              if (onSave) {
                if (isFinite(convertBalance)) {
                  const updComment = {
                    [EActionBalance.REFILL]: `#Пополнение ${comment}`,
                    [EActionBalance.WITHDRAWAL]: `#Снятие ${comment}`,
                  }

                  onSave(playerId, convertBalance, type, updComment[type])
                }
              }

              handleClose()
            }}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)

export default DialogAddPlayerBalance
