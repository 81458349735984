import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { endOfDay, format, startOfMonth } from 'date-fns'
import { DateRange } from 'react-day-picker'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_RowVirtualizer,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Box, Button, Stack } from '@mui/material'
import { MRT_Localization_RU } from 'material-react-table/locales/ru'
import { Link } from 'react-router-dom'
import Decimal from 'decimal.js'
import { toast } from 'react-toastify'
import { Currency, DateRangePicker } from '../index'
import { IPlayer, IPlayerFinance } from '../../api/players'
import { ApiPlayers } from '../../api'
import { dateFallback, EDateFormat, formatToMoscowTZ } from '../../utils/date'
import { moneyFormat } from '../../utils/money'
import useQueryOperators from '../../hooks/useQueryOperators'
import useQueryFinancesByPlayerId from '../../hooks/useQueryFinancesByPlayerId'
import { ERole } from '../../constants/role'

type PlayerFinancialHistoryProps = Pick<IPlayer, 'id' | 'login' | 'currency'>

const PlayerFinancialHistoryTable: FC<PlayerFinancialHistoryProps> = observer((props) => {
  const { id: playerId, login, currency } = props
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null)
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [range, setRange] = useState<DateRange>({
    from: dateFallback(startOfMonth(new Date())),
    to: dateFallback(endOfDay(new Date())),
  })

  const { data: operators } = useQueryOperators()

  const {
    data: finances,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQueryFinancesByPlayerId({
    playerId,
    ...range,
  })

  const totalAmount = useMemo(
    () => finances?.rows.reduce((acc, item) => acc.plus(new Decimal(item.amount)), new Decimal(0)) || new Decimal(0),
    [finances?.rows]
  )

  const columns: MRT_ColumnDef<IPlayerFinance>[] = [
    {
      id: 'date',
      accessorFn: (row) => new Date(row.createdAt),
      header: 'Время',
      sortingFn: 'datetime',
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        return formatToMoscowTZ(cell.getValue<Date>())
      },
    },
    {
      id: 'financeBefore',
      accessorFn: (row) => {
        return Number(row.financeBefore)
      },
      header: 'Баланс до',
      Cell: ({ cell }) => {
        const value = cell.getValue<number>()
        return (
          <>
            {moneyFormat(String(value.toFixed(2)))}&nbsp;
            <Currency currency={currency} />
          </>
        )
      },
    },
    {
      id: 'amount',
      accessorFn: (row) => {
        return Number(row.amount)
      },
      header: 'Сумма',
      Cell: ({ cell }) => {
        const value = cell.getValue<number>()
        return (
          <>
            {moneyFormat(String(value.toFixed(2)))}&nbsp;
            <Currency currency={currency} />
          </>
        )
      },
      Footer: () => {
        return (
          <Box component="span" color="warning.main">
            Всего: <br /> {moneyFormat(totalAmount.toNumber().toFixed(2))} <Currency currency={currency} />
          </Box>
        )
      },
    },
    {
      id: 'financeAfter',
      accessorFn: (row) => {
        return Number(row.financeAfter)
      },
      header: 'Баланс после',
      Cell: ({ cell }) => {
        const value = cell.getValue<number>()
        return (
          <>
            {moneyFormat(String(value.toFixed(2)))}&nbsp;
            <Currency currency={currency} />
          </>
        )
      },
    },
    {
      id: 'operator',
      accessorFn: (item) => item.balanceTransaction?.operatorTransaction?.login || '',
      header: 'Оператор',
      filterVariant: 'multi-select',
      filterFn: (row, id, filterValue: string[]) => {
        const value = row.getValue<string>(id)

        if (!filterValue?.length || 0) return true

        return filterValue.includes(value)
      },
      filterSelectOptions:
        operators?.rows.filter((item) => item.role === ERole.ADMINISTRATOR).map((item) => item.login) || [],
    },
    {
      accessorFn: (item) => {
        if (Object.keys(item?.balanceTransaction || {}).length) return item.balanceTransaction?.description
        return item.gameSessionResult?.sessionId || 'Слот'
      },
      header: 'Записи',
      Cell: ({ cell, row }) => {
        const value = cell.getValue<number | string>()
        const gameId = row.original?.gameSessionResult?.gameId

        if (typeof value === 'string' || !gameId) {
          return value
        }

        return <Link to={`/game/${gameId}/session/${value}/detail`}>{value}</Link>
      },
    },
  ]

  const handleExportData = async (): Promise<void> => {
    try {
      const { from, to } = range

      const queryDateFilters = {
        ...(from &&
          to && {
            from: format(from, EDateFormat.yyy_MM_dd_HH_mm),
            to: format(to, EDateFormat.yyy_MM_dd_HH_mm),
          }),
      }

      const response = await ApiPlayers.exportXlsxFinanceByPlayerId({
        playerId,
        ...queryDateFilters,
      })

      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${login}.finances.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      toast.error(`Error downloading the file ${error}`)
    }
  }

  useEffect(() => {
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0)
    } catch (error) {
      toast.error(`${error}`)
    }
  }, [sorting])

  const muiTable = useMaterialReactTable({
    columns,
    data: finances?.rows || [],
    enableRowVirtualization: true,
    rowVirtualizerOptions: { overscan: 3 },
    rowVirtualizerInstanceRef,
    enableFullScreenToggle: true,
    enablePagination: false,
    enableGlobalFilter: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    onSortingChange: setSorting,
    state: {
      sorting,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
    },
    initialState: {
      showColumnFilters: true,
      density: 'compact',
    },
    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <Stack gap={2} direction="row" alignItems="center">
          <DateRangePicker
            from={range.from}
            to={range.to}
            onChangeRange={(rangeDate) => {
              setRange({
                from: rangeDate?.from,
                to: rangeDate?.to,
              })
            }}
          />

          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              refetch()
            }}>
            Применить
          </Button>
          <Button size="small" variant="outlined" onClick={() => table.resetColumnFilters()}>
            Сбросить фильтры
          </Button>
          <Button size="small" variant="outlined" onClick={() => setSorting([])}>
            Сбросить сортировку
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={async () => {
              await handleExportData()
            }}
            startIcon={<FileDownloadIcon />}
            variant="contained">
            Экспорт в .xlsx
          </Button>
        </Stack>
      )
    },
    localization: MRT_Localization_RU,
  })

  return <MaterialReactTable table={muiTable} />
})

export default PlayerFinancialHistoryTable
