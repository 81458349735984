import { QueryOptions, useQuery, UseQueryResult } from 'react-query'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { ApiPlayers } from '../../api'
import { IGetPlayerFinances } from '../../api/players'
import { EDateFormat } from '../../utils/date'

const useQueryFinancesByPlayerId = (
  payload: {
    playerId: string
    from?: Date
    to?: Date
  },
  options?: QueryOptions<IGetPlayerFinances, Error>
): UseQueryResult<IGetPlayerFinances, Error> => {
  const { playerId, from, to } = payload
  return useQuery(
    [`finances player`, playerId],
    async () => {
      return ApiPlayers.getFinanceByPlayerId({
        playerId,
        ...(from &&
          to && {
            from: format(from, EDateFormat.yyy_MM_dd_HH_mm),
            to: format(to, EDateFormat.yyy_MM_dd_HH_mm),
          }),
      })
    },
    {
      enabled: !!playerId,
      refetchOnWindowFocus: false,
      onError: (err) => toast.error(`${err}`),
      ...options,
    }
  )
}

export default useQueryFinancesByPlayerId
