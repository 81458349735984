import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Box, Button, Chip, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { MaterialReactTable, MRT_ColumnDef, MRT_ColumnFiltersState, MRT_VisibilityState } from 'material-react-table'
import { MRT_Localization_RU } from 'material-react-table/locales/ru'
import { useInfiniteQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useLocalStorage } from 'react-use'
import { useInView } from 'react-intersection-observer'
import { EPlayerStatus } from '@lbs/status'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import ScoreboardIcon from '@mui/icons-material/Scoreboard'
import ReceiptIcon from '@mui/icons-material/Receipt'
import Decimal from 'decimal.js'
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../stores'
import { EDrawerView } from '../../stores/DrawerCRUD.store'
import {
  CrudIconBtn,
  Currency,
  DialogAddPlayerBalance,
  FilterBar,
  LoadMoreRows,
  MRTDateRangeFilter,
} from '../../components'
import PlayersV2, { IPlayerV2 } from '../../api/playersV2'
import { EDateFormat, formatDate } from '../../utils/date'
import { ApiPlayers } from '../../api'
import { ELocalstorageKeys } from '../../constants/localstorageKeys'
import { EActionBalance } from '../../constants/actionBalance'
import { mrtDateTimeFilter } from '../../services/mrtFilters'
import { moneyFormat } from '../../utils/money'

const fetchSize = 25

const Players: FC = observer(() => {
  const { drawerCRUDStore } = useStore()
  const navigate = useNavigate()
  const { ref, inView } = useInView()

  const [columnVisibilityLocalStorage, setColumnVisibilityLocalStorage] = useLocalStorage<MRT_VisibilityState>(
    ELocalstorageKeys.TABLE_PLAYERS_COLUMN_VISIBILITY,
    {}
  )
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    columnVisibilityLocalStorage as MRT_VisibilityState
  )
  const [columnFiltersLocalStorage, setColumnFiltersLocalStorage] = useLocalStorage<MRT_ColumnFiltersState>(
    ELocalstorageKeys.TABLE_PLAYERS_COLUMN_FILTERS,
    []
  )
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    columnFiltersLocalStorage as MRT_ColumnFiltersState
  )
  const [dialogBalance, setDialogBalance] = useState<{
    open: boolean
    playerId: string
    playerLogin: string
  }>({
    open: false,
    playerId: '',
    playerLogin: '',
  })

  const { data, isFetching, isError, isLoading, isFetchingNextPage, fetchNextPage, refetch } = useInfiniteQuery(
    ['players list'],
    async ({ pageParam = 0 }) => {
      return PlayersV2.getPlayers({
        limit: fetchSize,
        offset: pageParam * fetchSize,
      })
    },
    {
      enabled: true,
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => toast.error(`${err}`),
    }
  )

  const columns: MRT_ColumnDef<IPlayerV2>[] = [
    {
      accessorKey: 'login',
      header: 'Логин',
      muiFilterTextFieldProps: {
        label: 'Логин',
        size: 'small',
      },
    },
    {
      accessorKey: 'balance',
      header: 'Баланс',
      filterFn: 'greaterThanOrEqualTo',
      muiFilterTextFieldProps: {
        label: 'Баланс',
        size: 'small',
      },
      Cell: ({ cell, row }) => {
        const balance = cell.getValue<number>() || 0
        return (
          <Chip
            variant="filled"
            color={!Number(balance) ? 'error' : 'success'}
            size="small"
            label={
              <>
                {moneyFormat(String(balance))} <Currency currency={row.original.currency} />
              </>
            }
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              if (row.original.status !== EPlayerStatus.ACTIVE) {
                toast.error(`Игрок ${row.original.login} не активен`)
                return
              }

              setDialogBalance({
                open: true,
                playerId: row.original.id,
                playerLogin: row.original.login,
              })
            }}
          />
        )
      },
    },
    {
      accessorKey: 'sumDeposits',
      header: 'Игровой депозит',
      filterFn: 'greaterThanOrEqualTo',
      muiFilterTextFieldProps: {
        label: 'Игровой депозит',
        size: 'small',
      },
      Cell: ({ cell, row }) => {
        const deposit = cell.getValue<number>() || 0
        return (
          <div>
            <div>
              {moneyFormat(new Decimal(deposit).mul(new Decimal(row.original.rouletteRatio)).toFixed(2).toString())}{' '}
              <Currency currency={row.original.currency} />
            </div>
          </div>
        )
      },
    },
    {
      accessorFn: (row) => row.lastGameTransaction && new Date(row.lastGameTransaction.createdAt),
      header: 'Играл',
      id: 'lastPlayedBalanceDate',
      Cell: ({ cell, row }) => {
        const date: Date | null = cell.getValue<Date | null>()
        const { lastGameTransaction } = row.original
        return (
          <div>
            <div>{formatDate(date, EDateFormat.dd_MM_yyy_HH_mm)}</div>
            <div>{lastGameTransaction?.amount}</div>
          </div>
        )
      },
      sortingFn: 'datetime',
      filterFn: 'mrtDateTimeFilter',
      Filter: ({ column }) => {
        const { getFilterValue, setFilterValue } = column
        return (
          <MRTDateRangeFilter
            column={{
              // @ts-ignore
              getFilterValue,
              setFilterValue,
            }}
          />
        )
      },
    },
    {
      header: 'Пополнял',
      accessorFn: (row) => row.lastOperatorRefillTransaction && new Date(row.lastOperatorRefillTransaction.createdAt),
      id: 'lastRefillBalanceDate',
      sortingFn: 'datetime',
      filterFn: 'mrtDateTimeFilter',
      Cell: ({ cell, row }) => {
        const date: Date | null = cell.getValue<Date | null>()
        const { lastOperatorRefillTransaction } = row.original
        return (
          <div>
            <div>{formatDate(date, EDateFormat.dd_MM_yyy_HH_mm)}</div>
            <div>
              {lastOperatorRefillTransaction?.operatorTransaction.login}: {lastOperatorRefillTransaction?.amount}
            </div>
          </div>
        )
      },
      Filter: ({ column }) => {
        const { getFilterValue, setFilterValue } = column
        return (
          <MRTDateRangeFilter
            column={{
              // @ts-ignore
              getFilterValue,
              setFilterValue,
            }}
          />
        )
      },
    },
    {
      header: 'Снимал',
      accessorFn: (row) =>
        row.lastOperatorWithdrawalTransaction && new Date(row.lastOperatorWithdrawalTransaction.createdAt),
      id: 'lastWithdrawalBalanceDate',
      sortingFn: 'datetime',
      filterFn: 'mrtDateTimeFilter',
      Cell: ({ cell, row }) => {
        const date: Date | null = cell.getValue<Date | null>()
        const { lastOperatorWithdrawalTransaction } = row.original
        return (
          <div>
            <div>{formatDate(date, EDateFormat.dd_MM_yyy_HH_mm)}</div>
            <div>
              {lastOperatorWithdrawalTransaction?.operatorTransaction.login}:{' '}
              {lastOperatorWithdrawalTransaction?.amount}
            </div>
          </div>
        )
      },
      Filter: ({ column }) => {
        const { getFilterValue, setFilterValue } = column
        return (
          <MRTDateRangeFilter
            column={{
              // @ts-ignore
              getFilterValue,
              setFilterValue,
            }}
          />
        )
      },
    },
  ]

  useEffect(() => {
    if (!drawerCRUDStore.isOpen) refetch()
  }, [drawerCRUDStore.isOpen])

  useEffect(() => {
    setColumnVisibilityLocalStorage(columnVisibility)
  }, [columnVisibility])

  useEffect(() => {
    setColumnFiltersLocalStorage(columnFilters)
  }, [columnFilters])

  const players = data?.pages.flatMap((item) => item.rows ?? []) || []
  const playersRows = players.length
  const playersTotalRows = data?.pages?.[0]?.count || 0

  useEffect(() => {
    if (inView && playersRows < playersTotalRows) {
      fetchNextPage()
    }
  }, [inView])

  return (
    <>
      <Box>
        <FilterBar>
          <IconButton
            onClick={(e) => {
              e.preventDefault()

              drawerCRUDStore.changeView(EDrawerView.PLAYER_CREATE)
            }}>
            <AddIcon />
          </IconButton>
        </FilterBar>

        <MaterialReactTable
          localization={MRT_Localization_RU}
          enableFullScreenToggle={false}
          enableGlobalFilter={false}
          enableFilterMatchHighlighting={false}
          filterFns={{
            mrtDateTimeFilter,
          }}
          state={{
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isFetching,
            columnVisibility,
            columnFilters,
          }}
          initialState={{
            showColumnFilters: true,
            density: 'comfortable',
          }}
          onColumnVisibilityChange={setColumnVisibility}
          onColumnFiltersChange={setColumnFilters}
          columns={columns}
          data={players}
          enablePagination={false}
          muiTableBodyRowProps={({ row }) => ({
            onClick: (event) => {
              event.preventDefault()
              event.stopPropagation()

              drawerCRUDStore.changeView(EDrawerView.PLAYER_EDIT, row.original.id)
            },
            sx: {
              cursor: 'pointer',
            },
          })}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Button size="small" variant="outlined" onClick={() => table.resetColumnFilters()}>
                  Сбросить фильтры
                </Button>
              </Box>
            )
          }}
          renderBottomToolbarCustomActions={() => {
            return (
              <LoadMoreRows
                ref={ref}
                rows={playersRows}
                totalRows={playersTotalRows}
                isLoading={isFetchingNextPage}
                onLoad={() => {
                  fetchNextPage()
                }}
              />
            )
          }}
          enableEditing
          renderRowActions={({ row }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <CrudIconBtn
                color="info"
                tooltip="Финансовая история игрока"
                icon={<AccountBalanceWalletIcon />}
                onClick={() => {
                  navigate(`/player/${row.original.id}/finance`)
                }}
              />
              <CrudIconBtn
                color="info"
                tooltip="Результы игр"
                icon={<ScoreboardIcon />}
                onClick={() => {
                  navigate(`/player/${row.original.id}/game/results`)
                }}
              />

              <CrudIconBtn
                color="info"
                tooltip="Транзакции игрока"
                icon={<ReceiptIcon />}
                onClick={() => {
                  navigate(`/player/${row.original.id}/transactions`)
                }}
              />
            </Box>
          )}
        />
      </Box>
      {dialogBalance.open && (
        <DialogAddPlayerBalance
          isOpen={dialogBalance.open}
          playerId={dialogBalance.playerId}
          playerLogin={dialogBalance.playerLogin}
          onSave={async (playerId, balance, type, comment) => {
            try {
              await ApiPlayers.changeBalance(playerId, {
                amount: type === EActionBalance.REFILL ? balance : -balance,
                description: comment,
              })

              toast.success(`Успешная транзакция`)
            } catch (e) {
              toast.error(`${e}`)
            } finally {
              refetch()
            }
          }}
          onClose={() => {
            setDialogBalance({
              open: false,
              playerId: '',
              playerLogin: '',
            })
          }}
        />
      )}
    </>
  )
})

export default Players
